import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { getS3BackgroundUrl, getS3LogoUrl } from '../../../../utils/s3';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const { REACT_APP_ENV } = process.env

const S3_BUCKETS_DEFAULT = {
    staging: 'https://stg-vlab-assets.vlab.live/stg-assista_vlab_live/background.png',
    localhost: 'https://stg-vlab-assets.vlab.live/stg-assista_vlab_live/background.png',
    production: 'https://vlab-assets.vlab.live/assista_vlab_live/background.png'
}


const Container = styled.div`
  height: 100vh;
  ${props => css`
        background-image: url(${props.url}), url(${props.urlError});
    `}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 24px 24px 0px 24px;
  width: 18rem;
  background-color: #FFFFFF;
`;

const ContainerLogo = styled.div `
  width: 100%;
  height: 72px;
  ${props => css`
        background-image: url(${props.url});
    `}
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const BoxContainer = ({ children }) => {
  const [setting, setSettings] = useState({backgroundUrl: "", logoUrl: ""});
  const { t, i18n} = useTranslation()
  const { id } = useParams()

  useEffect(() => {
    getLabel()
  }, [])

  const getLabel = async () => {

    const backgroundUrl = await getS3BackgroundUrl(id)
    const logoUrl = await getS3LogoUrl(id)

    setSettings({backgroundUrl, logoUrl})
  }
  return (  
    <Container url={setting.backgroundUrl} urlError={S3_BUCKETS_DEFAULT[REACT_APP_ENV]}>
      <ContainerForm>
        <ContainerLogo url={setting.logoUrl}></ContainerLogo>
        {children}
      </ContainerForm>

      <div 
        style={{ 
            marginTop: 10, 
            backgroundColor: 'white', 
            padding: '12px 24px', 
            borderRadius: 8, 
            display: 'flex', 
            gap: 2, 
            alignItems: 'center', 
            justifyContent: 'center',
            color: '#5E5E5E',
            width: '18rem',
          }}>
        <span className='selected-language'>{t("changeLan.label")}</span>
        <button 
          className='selected-language-button' 
          style={i18n.language === 'pt-BR' ? { color: '#0145D5', fontWeight: 600 }: {}}
          onClick={() => { i18n.changeLanguage('pt-BR') }}
          >
            Português
        </button>
        |
        <button 
          className='selected-language-button' 
          style={i18n.language === 'en-US' ? { color: '#0145D5', fontWeight: 600 }: {}}
          onClick={() => { i18n.changeLanguage('en-US') }}
        >
          English
        </button>
        |
        <button 
          className='selected-language-button' 
          style={i18n.language === 'es' ? { color: '#0145D5', fontWeight: 600 }: {}}
          onClick={() => { i18n.changeLanguage('es') }}
        >
          Español
        </button>
      </div>
    </Container>
  );
};

export default BoxContainer;
