import React from 'react'

import FetcherRedirect from './fetcher/fetcher-redirect'
import { PatientProvider } from './contexts/patient-context'
import { MixpanelProvider } from 'react-mixpanel-browser';

import AppRoutes from './appRoutes'
import './styleGlobal.css'
import { SnackBarProvider } from './contexts/snackbar'
import { ExamProvider } from './contexts/examContext';

const MIXPANEL_TOKEN = 'db4724426b3d958a9c8a047c72f51248';

const App = () => (
    <MixpanelProvider token={MIXPANEL_TOKEN}>
        <PatientProvider>
            <ExamProvider>
                <SnackBarProvider> 
                    <AppRoutes />
                </SnackBarProvider>
            </ExamProvider>
        </PatientProvider>
    </MixpanelProvider>
)

export default App
