import storage from "../lib/storage"

const { REACT_APP_ENV } = process.env

const S3_URLS = {
    staging: 'https://stg-vlab-assets.vlab.live/',
    localhost: 'https://stg-vlab-assets.vlab.live/',
    production: 'https://vlab-assets.vlab.live/'
}

const S3_BUCKETS_DEFAULT = {
    staging: 'stg-assista_vlab_live',
    localhost: 'stg-assista_vlab_live',
    production: 'assista_vlab_live'
}

export const S3_URL = S3_URLS[REACT_APP_ENV]
export const S3_BUCKET_DEFAULT = S3_BUCKETS_DEFAULT[REACT_APP_ENV]

const getS3UrlByWindowLocation = (file) => {
    const hostnameDashed = window.location.hostname.replace(/[.]/g, '_')

    if(window.location.hostname === "localhost" || window.location.hostname === "local.vlab.live") {
        return `https://stg-vlab-assets.vlab.live/stg-assista_vlab_live/${file}`
    }
    
    return `${S3_URL}${hostnameDashed}/${file}`
}

const getS3UrlByOrganizationCode = async (file, id) => {
    
    const response = await fetch(`${S3_URL}orgRoutes.json`)
    const organizationsPath = await response.json()

    if(organizationsPath[id]) {

        return `${S3_URL}${organizationsPath[id]}/${file}`
    }

    return `${S3_URL}${S3_BUCKET_DEFAULT}/${file}`
}

const getS3Url = async (file, organization) => {

    if(!window.location.hostname.includes('vbaby.app')) {
        
        const url = getS3UrlByWindowLocation(file)
        return url
    }

    const id = organization ? organization : storage.getOrganization()
    const url = await getS3UrlByOrganizationCode(file, id)
    
    return url
}

export const getS3LogoUrl = async (organization) => await getS3Url('logo.png', organization)
export const getS3BackgroundUrl = async (organization) => await getS3Url('background.png', organization)
export const getS3SettingsUrl = async (organization) => {

    const url = await getS3Url('settings.json', organization)

    return await fetch(url)
}