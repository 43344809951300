import React, { useState, useEffect } from 'react'
import { getSettings } from '../lib/api'
import { getS3SettingsUrl } from '../utils/s3'
import { settingsFactory } from '../utils/functions'

const Context = React.createContext()

export const PatientProvider = ({ children }) => {
    const defaultSettings = {
        name: '',

        app: {
            showAccessApp: false,
        },

        live:{
            backgroundColor1:'',
            backgroundColor2:'',
            textColorLive:'',
            labelLive:'',
            logoTheme:''
        },
    }

    const defaultPatient = {
        patient: {}
    }

    const [settings, setSettings] = useState(defaultSettings)
    const [patient, setPatient] = useState(defaultPatient)

    useEffect(() => {
        fetchSettings()
    }, [])

    const fetchSettings = async () => {
        try {
            const response = await getS3SettingsUrl()
            const settingsJson = await response.json()

            if (response.status === 200 && !settingsJson['intercom']) {

                setSettings(settingsFactory(settingsJson))
            } else {
                setSettings(defaultSettings)
            }
        } catch (error) {
            console.log('error get settings', error)
            setSettings(defaultSettings)
        }
    }

    const providerValue = {
        settings,
        patient,
        setPatient,
        fetchSettings
    }

    return (
        <Context.Provider value={providerValue}>
            {children}
        </Context.Provider>
    )
}

export default Context
