import { useEffect, useState } from "react";
import { getPatient } from "../lib/api";
import { examFactory } from "../utils/functions";
import { useExam } from "../contexts/examContext";

export function useFetchExam() {
    const [isFetching, setIsFetching] = useState(true)
    const [exam, setExam] = useExam()
    const [error, setError] = useState(null)

    useEffect(() => {
        getPatient()
        .then(response => setExam(examFactory(response.data)))
        .catch(reason => setError(reason))
        .finally(() => setIsFetching(false))
    },[])

    return { isFetching, error }
}