import styled, { css } from "styled-components";

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


    ${(props) => (props.isMobile && !props.isLandscape) && css`
        margin-top: 12px;
        justify-content: space-between;
    `}
`;

const Header = styled.header`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    ${(props) => props.isLandscape && css`
        margin-top: 24px;
        flex-direction: row;
    `}
`

const PlayerContainer = styled.div`

    position: relative;
    box-sizing: border-box;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 40rem;

    ${(props) => (props.isMobile) && css`

        width: 100%;
    `}

    ${(props) => props.isLandscape && css`
        
        width: 32rem;
    `}
`

const ContainerVideo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${(props) => (props.isMobile && !props.isLandscape) && css`
        width: 100%;

        display: flex;
        justify-content: center;

        background-color: #FFF;
        padding: 0;
    `}
`;

const SelectLanguageContainer = styled.div`
    margin-top: 10px;
    background-color: white;
    padding: 12px 24px;
    border-radius: 8px;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    color: #5E5E5E;
    width: 18rem;
`

const StyledFloat = styled.div`
    position: fixed;
    top: 542px;
    display: flex;
    right: 20px;
    align-items: center;
    justify-content: center;
    @media (max-width: 480px) {
        top: 500px;
    }

    @media (max-width: 1023px) and (orientation: landscape) {
        top: 300px;
        right: 20px;
    }
`;

const ContainerLogo = styled.div `
    width: 100%;
    height: 54px;
    ${props => css`
        background-image: url(${props.url});
    `}
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`;

const LabelExam = styled.p`
    color: '#020523';
    font-weight: 700;
    font-family: 'Poppins', sans-serif;    
    font-style: normal;
    font-size: 18px;
    line-height: normal;
    display: flex;
    justify-content: center;
    letter-spacing: 0.5px;
    white-space: nowrap;
`;

const ContainerLabel = styled.div`
    display: flex;
    width: 50%;
    align-items: end;
    justify-content: flex-end;
    gap: 32px;

    ${(props) => props.isMobile && css`
        justify-content: space-evenly;
        width: 100%;
        padding: 2px;
    `}

    ${(props) => props.isLandscape && css`
        width: 100%;
        gap: 0;
        justify-content: space-evenly;
    `}
`;

const ContainerButton = styled.div`
    display: flex;
    box-sizing: border-box;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 8px;
    align-items: center;
    justify-content: space-between;

    ${(props) => (props.isMobile) && css`

      padding: 0 8px;

    `}
`;

const ContainerDescription = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 8px;

    ${(props) => props.isMobile && css`
        flex-direction: column;
        justify-content: center;
        margin-top: 24px;
        margin-bottom: 24px;
    `}

    ${(props) => (props.isLandscape) && css`
        flex-direction: column;
        justify-content: space-between;
        margin-top: 8px;
        margin-bottom: 8px;
    `}
`;

export {
    Container,
    Header,
    PlayerContainer,
    ContainerVideo,
    SelectLanguageContainer,
    StyledFloat,
    ContainerLogo,
    LabelExam,
    ContainerLabel,
    ContainerButton,
    ContainerDescription
}