export default {
  translations: {
    country: 'Estados Unidos',
    login: {
      phone: 'Phone',
      accessCode: 'Access code',
      forgotAcess: 'Forgot access code',
      phoneComponent: {
        searchPlaceholder: 'Search country',
        searchNotFound: 'No matching country',
      },
    },
    recovery: {
      recoveringCode: 'Recovering code',
      smsCode: 'You will receive the access code for your last exam via SMS shortly',
      didntArrive: "Didn't arrive? You can request to resend it in {{countdown}} seconds",
    },
    notFound: {
      notPage: 'We couldn\'t find this page',
      openExam: 'You can open the exam with the phone number and access code or request a new link from the sender.',
      goToLogin: 'Go to login',
    },    
    buttons: {
      enter: 'Enter',
      back: 'Back',
      recovery: 'Recovery',
      resend: 'Resend',
      understood: 'Understood',
      download: 'Download',
      share: 'Share',
      copyLink: 'Copy link',
      whatsapp: 'WhatsApp',
      sendFeedback: 'Send feedback',
      sendSupport: 'Request support',
      goToApp: 'GO TO APP',
      selectFlag: 'Select your language',
      jump: 'Skip intro',
    },
    loadingExam: 'Loading exam',
    watch: {
      examDay: 'Exam on {{date}}',
      realized: 'Realized at {{date}}',
      statusRecorded: 'Recorded',
      statusLive: 'Live',
      statusSoon: 'Soon',
    },
    dialog: {
      experience: 'How is your experience?',
      leaveComment: 'Leave a comment about your experience',
      videoDifficulties: 'Oops! We detected a possible issue with the playback of your exam.',
      talkSupport: 'For a quick solution, please contact our support right away. We are here to help!',
      downloadApp: 'Download the V-Baby app',
      downloadingApp: 'By downloading the V-Baby app, your experience becomes more complete! Watch exams, track your baby\'s growth, and much more!',
      errorDownload: 'Oops! We detected a possible issue while downloading your exam.',
    },
    shareMessages: {
      title: 'My Ultrasound',
      textLive: 'Hi everyone! 🌟\nMy ultrasound is happening now! Come watch it live through this link:\n',
      textRecorded: 'Hello! \nThe ultrasound recording of our baby is available. 🌟 \n',
    },
    supportMessages: {
      messageViewUser: 'Hello! \nI\'m having trouble viewing my exams on Assista. \nI need help to solve the problem.',
      messageDownloadUser: 'Olá! \nI\'m having trouble downloading my exams on Assista. \nPreciso de ajuda para solucionar o problema.',
      messageHelp: 'Hello! \nI\'m having difficulties with the Assista solution. Could you help?',
      askSupport: 'Ask for support',
    },  
    alertMessages: {
      errorData: '{{error}} Incorrect phone number or access code.',
      requiredCode: 'Access code is required!',
      validNumber: 'Enter a valid phone number.',
      sendError: 'There was an error sending your access code, please try again.',
      copiedLink: 'Link copied to clipboard',
      errorLink: 'Failed to copy link to clipboard',
      shareSuccess: 'Successful sharing!',
      downloadingExam: 'Downloading exam',
      babyDetails: 'Loading all baby details...',
      preparingVideo: 'Preparing the best video...',
      concluded: 'Completed!',
      noContent: 'It\'s not possible to download the exam yet, please wait until the livestream is finished.',
      error: 'Error downloading exam',
      downloadError: 'An unexpected error occurred, please try again later.',
      examLiveError: 'Error while broadcasting the exam'
    },
    acceptTerms: {
      privacyPolitics: 'I have read [termsLink]Terms of Use[/termsLink] and [termsPolitic]Privacy Policy[/termsPolitic].',
      healthPolitics: 'I have read, agree and consent to the [termsHealth]Health Data Collection Term[/termsHealth].', 
    },
    poweredBy: {
      title: 'Ultrasound recording by'
    },

    changeLan: {
      label: 'Change Language:'
    },

    footer: {
      label: "Made with 💜 by "
    },

    endedExam: {
      title: 'This live stream has ended!',
      description: 'The recording is available on the V-Baby app, only for the number provided at the clinic.',
      descriptionTwo: 'The recording is available in the Test Result - '
    },

    terms: {
      privacyPolitics: "Privacy Policies",
      termsOfUse: "Terms of Use",
      healthTerms: "Health Data Collection Term"
    }
  },
};
