import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled, {css} from 'styled-components';
import { isMobileDevice, landscape } from '../../utils/mobile-detect';

import AppleStoreLogo from '../../assets/apple_store_logo.png'
import PlayStoreLogo from '../../assets/play_store_logo.png'

const URL_PLAY_STORE = 'https://play.google.com/store/apps/details?id=com.vlab.vbaby&utm_source=vbaby-assista&utm_content=buttom'
const URL_APPLE_STORE = 'https://apps.apple.com/app/apple-store/id1583958416?pt=123391444&ct=vbaby-assista&mt=8'

const Container = styled.div`
    
    background-color: white;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 28.125% 0; /* 16:9 proporção (9 / 16 * 100%) */
    height: 0;
    overflow: hidden;
    width: 40rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    text-align: center;

    h2 {
        font-family: 'Poppins', sans-serif;
        font-size: 0.975rem;
        color: #7B61FF;
    }

    p {
        font-family: 'Poppins', sans-serif;
        color: #5E5E5E;
        text-align: center;
        font-size: 2rem;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        padding: 16px;
    }

    ${(props) => props.isMobileLandscap && css`
        
        width: 32rem;
    `}

    @media (min-width: 1440px) { 
      width: 68rem;
    }

    @media (max-width: 767px) { 
      width: calc(100% - 18px);
      border-radius: 12px;
    
      height: 378px;
      margin: 16px;
      
      h2 {

        font-size: 0.875rem;
      }

      p {
        padding: 32px;
        font-size: 1rem;
      }

    }
`

const ImageContainer = styled.div`
    
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;

    img {

        width: 150px;
        height: 45px;

        cursor: pointer;
    }
`

const handleOpenAppleStore = () => {
    window.dataLayer.push({
      event: 'go-to-app',
    });

    window.open(URL_APPLE_STORE, '_blank')
}

const handleOpenPlayStore = () => {
    window.dataLayer.push({
        event: 'go-to-app',
    });

    window.open(URL_PLAY_STORE, '_blank')
}

export function EndedExam({flowType, organization}) {
    
    const { t, i18n } = useTranslation();
    const isMobileLandscap = landscape()

    return (
        flowType === 0 ?
            <Container isMobileLandscap={isMobileLandscap}>
                <h2>{t('endedExam.title')}</h2>
                <p>{t('endedExam.description')}</p>
                <ImageContainer>
                    <img onClick={handleOpenAppleStore} src={AppleStoreLogo} alt='Apple store logo' />
                    <img onClick={handleOpenPlayStore} src={PlayStoreLogo} alt='Play store logo' />
                </ImageContainer>
            </Container>
            :
            <Container isMobileLandscap={isMobileLandscap}>
                <h2>{t('endedExam.title')}</h2>
                <p>{t('endedExam.descriptionTwo')} {organization}</p>
            </Container>
    );
}