import React from 'react';
import Clappr from "clappr";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'react-mixpanel-browser';

const Player = (props) => {
  let videoRef = null
  const playerRef = React.useRef(null);
  const {options, onError, globalPhone, endedExam } = props;
  const mixpanel = useMixpanel()
  let videoEnded = false;

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    const hlsjsConfig = {
      nudgeOffset: 4,
      nudgeMaxRetry: 60,
      liveSyncDurationCount: 2,
      maxMaxBufferLength: 12,
      debug: "_LIVE_MODE_",
    };

    videoRef = new Clappr.Player({
      parentId: "#player",
      source: getSource(),
      width: "100%",
      height: "100%",
      hlsjsConfig,
      playback: {
        playInline: true,
      },
      events: {
        onEnded: () => playNextVideo(videoRef),
        onError: (event) => {
          const errorCode = event.code;
          const errorTitle = event.UI.title; 
          const errorMessage = event.UI.message; 
          const errorLevel = event.level;
          const errorOrigin = event.origin;

          console.log()

          mixpanel.track('PLAYER_ERROR_ASSISTA', {
            distinct_id: globalPhone,
            'PLAY_EXAM': false,
            'ERROR_CODE': errorCode,
            'ERROR_TITLE': errorTitle,
            'ERROR_LEVEL': errorLevel,
            'ERROR_ORIGIN': errorOrigin,
            'ERROR_MESSAGE': errorMessage,
          });
          onError()
        }
      }
    });
    
    videoRef.play()
    videoRef.once(Clappr.Events.PLAYER_PLAY, sendEvent)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, [videoRef]);


  const getSource = () => {
    if(!options.sources[0].src) {
      videoEnded = true

      return options.sources[1].src
    }

    return options.sources[0].src
  }


  const playNextVideo = () => {

    if(!videoEnded) {

      if(!options.sources[1].src) {

        onError()
        return
      }
      videoRef.load({ source: options.sources[1].src })
      videoRef.play()
      videoEnded = true

    } else {
      
      if(endedExam) {
        endedExam(true)
      }
    }       
  }
  

  const handleBeforeUnload = (event) => {
    if (videoRef) {
      const videoCurrentTime = videoRef.getCurrentTime();
      const videoDurationTime = videoRef.getDuration();
  
      mixpanel.track('PLAYER_ASSISTA', {
        distinct_id: globalPhone,
        'CURRENT_TIME_WATCH': videoCurrentTime,
        'DURATION_EXAM': videoDurationTime,
      });
    }
  };

  const sendEvent = () => {
    window.dataLayer.push({
      event: window.location.href.includes("shared") ? 'shared_play_video' : 'play_video',
    });

    if(window.location.href.includes("share")) {

      mixpanel.track('WEBAPP_PLAY_SHARED_VIDEO', { 
        distinct_id: globalPhone,
      });

    } else {
      
      mixpanel.track('WEBAPP_PLAY_VIDEO', { 
        distinct_id: globalPhone,
      });

    }

  }

  return (
        <ClapprPlayer id="player" style={{ width: '100%' }}/>
    );
}

export default Player

const Button = styled.button`
  visibility: hidden;
  position: absolute;
  bottom: 10px;
  right: 0;
  padding: 10px 20px;
  background-color: black;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 10px;
`;

const ClapprPlayer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
`